<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "CloudKitch",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },

   mounted() {
          // if((this.$storageData.login_type && this.$storageData.login_type > 0) && (this.$storageData.profile.empTypeID && this.$storageData.profile.empTypeID!=9)){
          //       this.$router.push('/dashboard/superAdminDashboard');
          //   }
          //   else if((this.$storageData.login_type && this.$storageData.login_type==2) || (this.$storageData.profile.empTypeID && this.$storageData.profile.empTypeID==9))
          //   {
          //       this.$router.push('/dashboard/restaurantDashboard');
          //   }
          //   else if(this.$storageData.login_type && this.$storageData.login_type==3)
          //   {
          //       this.$router.push('/dashboard/corporateDashboard');
          //   }
          //   else if(this.$storageData.login_type && this.$storageData.login_type==4)
          //   {
          //       this.$router.push('/dashboard/principleDashboard');
          //   }
     if(this.$storageData.login_type && this.$storageData.login_type > 0 && this.$storageData.url && this.$storageData.url!='') {
       this.$router.push(this.$storageData.url);
     }
            else{
                this.$router.push('/mainLoginPage');
            } 
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
       
    </div>
    
  </Layout>
</template>